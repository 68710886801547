import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const NotreMission = () => (
  <Layout linkColor="#5388ff" language="fr">
    <SEO title="Our mission"/>
  <div className="wavy">
    <div className="paddedBody grayBody">
    <h1>Mission</h1>
    <p>L’être humain est la seule espèce animale sur terre que l’on retrouve dans tous les coins de cette planète. Même au milieu de nulle part dans l’océan Pacifique, il existe des petites îles où l’on trouve des êtres humains. Ils sont là depuis des millénaires.</p>
    <p>Peut-être ont-ils dû se dégager à cause d’un clan opposant, peut-être y a-t-il eu un tremblement de terre qui a détruit leur commune, peut-être faisait-il trop froid, trop chaud, ou  peut-être qu’il ne pleuvait pas assez.</p>
    <p>Ils auraient pu prendre leurs chevaux et leurs ânes pour se déplacer vers un environnement accueillant par voie terrestre. Ils ont plutôt fait le choix ridicule de bâtir des pirogues pour voguer vers l’inconnu… Pensez-y, ils étaient au large en pirogue…</p>
    <p>On ne pourra jamais comprendre cette décision en évaluant des incitatifs logiques. Cherchons plutôt la réponse dans l’absurde.</p>
    <p>Ce désir inépuisable de l’être humain de savoir ce qu’il y aurait s’il pousse un peu plus loin: qu’est-ce qu’on verra? Qui est là? Y a t-il des animaux étranges? Est-ce qu’il y aura de l’air à respirer? De l’eau?</p>
    <p>Ce désir absurde qui nous oblige à constamment apprendre, à continuellement découvrir, s’explique-t-il rationnellement ?</p>
    <p>Et pourtant, c’est ce désir absurde qui a mis  l’homme sur la lune, qui a imaginé le zéro, qui a découvert pourquoi les pommes tombent des arbres et qui a envoyé des explorateurs au milieu de nulle part dans l’océan Pacifique.</p>
    <br />
    <p>L’exploration vous intéresse? Écrivez-nous au <a href="mailto:jonarlabs@jonar.com">jonarlabs@jonar.com</a>.</p></div>
  </div>
  </Layout>
)

export default NotreMission
